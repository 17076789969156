import { Button, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/data-grid';
import { useState } from 'react';
import GRCBreadCrumb from '../UIComponents/GRCBreadCrumb';
import GRCCompanyGrid from '../UIComponents/GRCDataGrid';
import '../assets/css/company.css';
import { AppPages } from '../constants/genericConstants';
import { useCompany } from '../contexts/CompanyContext';
import { useParams, useNavigate } from 'react-router-dom';
import { COMPANY_FORM_OPERATIONS } from '../components/CompanyForm.constants';

function CompanyList({ prop }) {
  const { env } = useCompany();
  const { id } = useParams();
  const navigate = useNavigate();
  console.log(id);
  const [onboardPage, setOnBoardPage] = useState(AppPages.Company);
  return (
    <>
      <div className='container-fluid' id='companyContainer'>
        <div className='row mt-4 '>
          <div className='col-md-12 '>
            <div className='card ' style={{ minHeight: '84vh' }}>
              <h5 className='card-header'>
                {/* {!displayRegistration ? 'Company' : 'OnBoard'} */}
              </h5>
              <div className='card-body'>
                <GRCBreadCrumb
                  bcProp={{
                    page: onboardPage,
                    // setDisplayRegistration,
                    //setDisplayCompany: prop.setDisplayCompany,
                  }}
                ></GRCBreadCrumb>
                <div className='row mt-4 justify-content-center'>
                  <>
                    <Toolbar className='justify-content-right'>
                      <Item
                        style={{
                          border: 'solid',
                          borderColor: 'black',
                          float: 'left',
                        }}
                      >
                        <Button
                          icon='plus'
                          onClick={() =>
                            navigate('/add-edit-company', {
                              state: { operation: COMPANY_FORM_OPERATIONS.ADD },
                            })
                          }
                        ></Button>
                      </Item>
                    </Toolbar>
                    <GRCCompanyGrid
                      prop={{ env: env, setOnBoardPage }}
                    ></GRCCompanyGrid>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyList;

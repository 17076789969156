import React, { createContext, useContext, useReducer } from 'react';
// import API from '../api/api';

export const CompanyContext = createContext({});
export const useCompany = () => useContext(CompanyContext);

const companyActions = {
  MAKE_REQUEST: 'MAKE_REQUEST',
  SET_USER_DATA: 'SET_USER_DATA',
  ERROR: 'ERROR',
};

const initialState = {
  loading: false,
  companyData: {
    companyName: '',
    companyType: '',
  },
  totalPages: 0,
  totalResults: 0,
  hasMoreData: false,
  error: '',
  env: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case companyActions.MAKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case companyActions.SET_USER_DATA:
      return {
        ...state,
        loading: false,
        companyData: action.payload.data.results,
        totalPages: action.payload.data.totalPages,
        totalResults: action.payload.data.totalResults,
        hasMoreData: action.payload.hasMoreData,
      };
    case companyActions.SET_ENVIRONMENT:
      return {
        ...state,
        env: action.payload,
      };
    default:
      return state;
  }
};

export const CompanyDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //   const createCompany = async (body) => {
  //     try {
  //       return await API.createCompany(body);
  //     } catch (error) {
  //       dispatch({
  //         type: companyActions.ERROR,
  //         payload:
  //           error.response && error.response.data.message
  //             ? error.response.data.message
  //             : error.message,
  //       });
  //     }
  //   };

  //   const getCompany = async (params) => {
  //     try {
  //       dispatch({ type: companyActions.MAKE_REQUEST });
  //       const { data } = await API.getCompany(params);
  //       //   console.log(data)
  //       dispatch({
  //         type: companyActions.SET_USER_DATA,
  //         payload: {
  //           data,
  //           hasMoreData: data.page !== data.totalPages,
  //         },
  //       });
  //     } catch (error) {
  //       dispatch({
  //         type: companyActions.ERROR,
  //         payload:
  //           error.response && error.response.data.message
  //             ? error.response.data.message
  //             : error.message,
  //       });
  //     }
  //   };

  const setEnvironment = (env) => {
    dispatch({ type: companyActions.SET_ENVIRONMENT, payload: env });
  };

  const setCompany = (company) => {
    dispatch({ type: companyActions.SET_COMPANY, payload: company });
  };

  console.log(state.env);
  return (
    <CompanyContext.Provider
      value={{
        ...state,
        setEnvironment,
        setCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, } from 'react-router-dom'
import { MsalProvider } from "@azure/msal-react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);


const root = ReactDOM.createRoot(document.getElementById("root"));
console.log('msalInstance index.js', msalInstance)
root.render(
    <Router>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const AppPages = Object.freeze({
  Home: "home",
  Company: "company",
  Onboard: "onboard",
});

export const Env = Object.freeze({
  DEV: "dev",
  UAT: "uat",
  DEMO: "demo",
  PROD: "prod",
});

export const environments = [
  {
    envId: 1,
    environment: Env.DEV,
    cardColor: "card-box bg-orange shadowCls",
  },
  {
    envId: 2,
    environment: Env.UAT,
    cardColor: "card-box bg-red shadowCls",
  },
  {
    envId: 3,
    environment: Env.DEMO,
    cardColor: "card-box bg-blue shadowCls",
  },
  {
    envId: 4,
    environment: Env.PROD,
    cardColor: "card-box bg-green shadowCls",
  },
];

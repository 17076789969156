import * as Yup from 'yup';

export const companySizeOptions = [
  { value: '1', label: 'Self-employed' },
  { value: '1-10', label: '1-10 employees' },
  { value: '11-30', label: '11-30 employees' },
  { value: '30-50', label: '31-50 employees' },
  { value: '51-100', label: '51-100 employees' },
  { value: '100+', label: '100+ employees' },
];

export const industryTypes = [
  'Accounting',
  'Banking',
  'Capital Markets',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software',
  'Financial Services',
  'Information Technology and Services',
  'Insurance',
  'Investment Banking',
  'Investment Management',
  'Retail',
  'Media',
];

export const REQUIRED_MESSAGE = 'This is a required field';

export const schema = Yup.object().shape({
  companyName: Yup.string().min(3).required(REQUIRED_MESSAGE),
  subscription: Yup.string().required(REQUIRED_MESSAGE),
  industryType: Yup.string().required(REQUIRED_MESSAGE),
  companySize: Yup.string().required(REQUIRED_MESSAGE),
  jobTitle: Yup.string().required(REQUIRED_MESSAGE).min(2),
  firstName: Yup.string().min(3).required(REQUIRED_MESSAGE),
  lastName: Yup.string().min(3).required(REQUIRED_MESSAGE),
  email: Yup.string().required(REQUIRED_MESSAGE),
  phone: Yup.string().min(10).required(REQUIRED_MESSAGE),
  addressLine1: Yup.string().required(REQUIRED_MESSAGE),
  addressLine2: Yup.string().min(2),
  city: Yup.string().required(REQUIRED_MESSAGE),
  state: Yup.string().required(REQUIRED_MESSAGE),
  zip: Yup.string().required(REQUIRED_MESSAGE),
  country: Yup.string().required(REQUIRED_MESSAGE),
  website: Yup.string().required(REQUIRED_MESSAGE).min(2),
  emailDomain: Yup.string().min(2),
  vision: Yup.string().min(2),
  mission: Yup.string().min(2),
  description: Yup.string().min(2),
});

export const companyDefaultData = {
  companyName: '',
  subscription: '1',
  industryType: 'Accounting',
  companySize: '1',
  jobTitle: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  website: '',
  emailDomain: '',
  vision: '',
  mission: '',
  description: '',
};

export const COMPANY_FORM_OPERATIONS = { ADD: 'add', EDIT: 'edit' };

import {
    useMsal,
    MsalAuthenticationTemplate,
    useIsAuthenticated,
} from "@azure/msal-react";
import {
    InteractionType,
    InteractionStatus,
    IPublicClientApplication,
    AccountInfo,
} from "@azure/msal-browser"
import NavBar from "./NavBar";

function AuthenticatedComponent({ children }) {
    const { inProgress, instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    if (!isAuthenticated && inProgress !== InteractionStatus.None) {
        return (
            <div class="row">
                <div class="col-md-12 col-sm-12 mx-auto">
                    <div class="card position-relative overflow-hidden">
                        <div class="card-body">...</div>
                        <div class="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
                            <div class="spinner-border" role="status">
                                <span class="sr-only visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <NavBar />
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                {children}
            </MsalAuthenticationTemplate>
        </>
    )
}

const styles = {
    spinner: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    }
}

export default AuthenticatedComponent;
import { Link } from "react-router-dom";
import { AppPages } from "../constants/genericConstants";

const GRCBreadCrumb = ({ bcProp }) => {
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => bcProp.setDisplayCompany(false)}
            >
              Home
            </Link>
          </li>
          {bcProp.page >= AppPages.Company && (
            <li className="breadcrumb-item">
              <Link
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => bcProp.setDisplayRegistration(false)}
              >
                Company
              </Link>
            </li>
          )}
          {bcProp.page >= AppPages.Onboard && (
            <li className="breadcrumb-item active" aria-current="page">
              Onboard
            </li>
          )}
        </ol>
      </nav>
    </>
  );
};

export default GRCBreadCrumb;

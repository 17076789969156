const API_URI = process.env.REACT_APP_GRC_SERVICE;
// https://cleargrcadmin-api.azurewebsites.net
export async function getSubscriptionList(env) {
  return fetch(`${API_URI}/subscription/list`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ env: env }),
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => {
      console.log('err - ', err);
    });
}

import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  companySizeOptions,
  schema,
  companyDefaultData,
  COMPANY_FORM_OPERATIONS,
  industryTypes as industryTypeArray,
} from './CompanyForm.constants';
import { useNavigate } from 'react-router-dom';
import { createCompany, updateCompany } from '../services/companyService';
import { useCompany } from '../contexts/CompanyContext';
import { getSubscriptionList } from '../services/subscriptionService';

const CompanyForm = ({ operation, companyData }) => {
  const [initialValues, setInitialValues] = useState(companyDefaultData);
  const [loading, setLoading] = useState(true);
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const { env } = useCompany();

  useEffect(() => {
    setInitialValues(companyData);
    setLoading(false);
    getSubscriptionList(env).then((data) => {
      console.log('getSubscriptionList(env):', data);
      setSubscriptionOptions(data);
    });
  }, [companyData]);
  const { companyName } = companyData;
  const navigate = useNavigate();
  const handleOnSubmit = (values) => {
    if (operation === COMPANY_FORM_OPERATIONS.ADD) {
      createCompany(env, values)
        .then((response) => response.json())
        .then((data) => {
          if (data === 'Success') {
            alert('Company got created successfully!');
            navigate('/company-list');
          } else {
            alert('Error in creating company:', data);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('Error in creating company:', error);
        });
    } else {
      updateCompany(env, values)
        .then((response) => response.json())
        .then((data) => {
          const { statusCode } = data;
          if (statusCode === 200) {
            alert('Company got updated successfully!');
            navigate('/company-list');
          } else {
            alert('Error in updating company', data);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('Error in updating company:', error);
        });
    }
  };

  const formik = useFormik({
    enableReinitialize: true, // This allows the form to be reinitialized with new initial values
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleOnSubmit,
  });

  console.log('Formik errors:', formik.errors);

  if (loading) {
    return <div>Loading...</div>; // Show loading message while data is being fetched
  }

  return (
    <>
      <h1>
        {operation === COMPANY_FORM_OPERATIONS.ADD
          ? 'Adding Company Details'
          : `Editing Company Details for ${companyName}`}
      </h1>
      <form onSubmit={formik.handleSubmit} className='form-group'>
        <div className='row mb-4'>
          {/* Company Name */}
          <div className='form-group col-md-3'>
            <label htmlFor='companyName'>Company Name</label>
            <input
              id='companyName'
              name='companyName'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.companyName}
              className='form-control'
              required
            />
            {formik.errors.companyName && (
              <small className='text-danger'>{formik.errors.companyName}</small>
            )}
          </div>
          {/* Subscription */}
          <div className='form-group col-md-3'>
            <label htmlFor='subscription'>Subscription Type</label>
            <select
              id='subscription'
              name='subscription'
              onChange={formik.handleChange}
              value={formik.values.subscription}
              className='form-control'
              defaultValue={1}
            >
              {subscriptionOptions?.map((subs) => {
                return <option value={subs['id']}>{subs['name']}</option>;
              })}
              {/* {subscriptionOptions.map((option, index) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))} */}
            </select>
            {formik.errors.subscription && (
              <small className='text-danger'>
                {formik.errors.subscription}
              </small>
            )}
          </div>
          {/* Industry Type */}
          <div className='form-group col-md-3'>
            <label htmlFor='industryType'>Industry Type</label>
            <select
              id='industryType'
              name='industryType'
              onChange={formik.handleChange}
              value={formik.values.industryType}
              className='form-control'
            >
              {industryTypeArray.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {formik.errors.industryType && (
              <small className='text-danger'>
                {formik.errors.subscription}
              </small>
            )}
          </div>
          {/* Company Size */}
          <div className='form-group col-md-3'>
            <label htmlFor='companySize'>Company Size</label>
            <select
              id='companySize'
              name='companySize'
              onChange={formik.handleChange}
              value={formik.values.companySize}
              className='form-control'
            >
              {companySizeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {formik.errors.companySize && (
              <small className='text-danger'>{formik.errors.companySize}</small>
            )}
          </div>
          <br />
          {/* <h3>Additional Info</h3> */}
          {/* Website */}
          <div className='form-group col-md-3'>
            <label htmlFor='website'>Website</label>
            <input
              id='website'
              name='website'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.website}
              className='form-control'
            />
            {formik.errors.website && (
              <small className='text-danger'>{formik.errors.website}</small>
            )}
          </div>
          {/* Domains */}
          <div className='form-group col-md-3'>
            <label htmlFor='emailDomain'>Domains</label>
            <input
              id='emailDomain'
              name='emailDomain'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.emailDomain}
              className='form-control'
            />
            {formik.errors.emailDomain && (
              <small className='text-danger'>{formik.errors.emailDomain}</small>
            )}
          </div>
          <br />
          {/* Vision */}
          <div className='form-group col-md-6'>
            <label htmlFor='vision'>Vision</label>
            <textarea
              id='vision'
              name='vision'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.vision}
              className='form-control'
            />
            {formik.errors.vision && (
              <small className='text-danger'>{formik.errors.vision}</small>
            )}
          </div>
          {/* Mission */}
          <div className='form-group col-md-6'>
            <label htmlFor='mission'>Mission</label>
            <textarea
              id='mission'
              name='mission'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.mission}
              className='form-control'
            />
            {formik.errors.mission && (
              <small className='text-danger'>{formik.errors.mission}</small>
            )}
          </div>
          {/* Description */}
          <div className='form-group col-md-6'>
            <label htmlFor='description'>Description</label>
            <textarea
              id='description'
              name='description'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.description}
              className='form-control'
            />
            {formik.errors.description && (
              <small className='text-danger'>{formik.errors.description}</small>
            )}
          </div>
          <br />
          <h3>Address</h3>
          {/* Address */}
          {/* Address Line 1 */}
          <div className='form-group col-md-3'>
            <label htmlFor='addressLine1'>Address Line 1</label>
            <input
              id='addressLine1'
              name='addressLine1'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.addressLine1}
              className='form-control'
            />
            {formik.errors.addressLine1 && (
              <small className='text-danger'>
                {formik.errors.addressLine1}
              </small>
            )}
          </div>
          {/* City */}
          <div className='form-group col-md-3'>
            <label htmlFor='city'>City</label>
            <input
              id='city'
              name='city'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.city}
              className='form-control'
            />
            {formik.errors.city && (
              <small className='text-danger'>{formik.errors.city}</small>
            )}
          </div>
          {/* State */}
          <div className='form-group col-md-3'>
            <label htmlFor='state'>State</label>
            <input
              id='state'
              name='state'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.state}
              className='form-control'
            />
            {formik.errors.state && (
              <small className='text-danger'>{formik.errors.state}</small>
            )}
          </div>
          {/* Zip */}
          <div className='form-group col-md-3'>
            <label htmlFor='zip'>Zip</label>
            <input
              id='zip'
              name='zip'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.zip}
              className='form-control'
            />
            {formik.errors.zip && (
              <small className='text-danger'>{formik.errors.zip}</small>
            )}
          </div>
          {/* Country */}
          <div className='form-group col-md-3'>
            <label htmlFor='country'>Country</label>
            <input
              id='country'
              name='country'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.country}
              className='form-control'
            />
            {formik.errors.country && (
              <small className='text-danger'>{formik.errors.country}</small>
            )}
          </div>
          <br />
          <h3>Contact Person</h3>
          {/* Job Title */}
          <div className='form-group col-md-3'>
            <label htmlFor='jobTitle'>Job Title</label>
            <input
              id='jobTitle'
              name='jobTitle'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.jobTitle}
              className='form-control'
            />
            {formik.errors.jobTitle && (
              <small className='text-danger'>{formik.errors.jobTitle}</small>
            )}
          </div>
          {/* First Name */}
          <div className='form-group col-md-3'>
            <label htmlFor='firstName'>First Name</label>
            <input
              id='firstName'
              name='firstName'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.firstName}
              className='form-control'
            />
            {formik.errors.firstName && (
              <small className='text-danger'>{formik.errors.firstName}</small>
            )}
          </div>
          {/* Last Name */}
          <div className='form-group col-md-3'>
            <label htmlFor='lastName'>Last Name</label>
            <input
              id='lastName'
              name='lastName'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.lastName}
              className='form-control'
            />
            {formik.errors.lastName && (
              <small className='text-danger'>{formik.errors.lastName}</small>
            )}
          </div>
          {/* Email */}
          <div className='form-group col-md-3'>
            <label htmlFor='email'>Email</label>
            <input
              id='email'
              name='email'
              type='email'
              onChange={formik.handleChange}
              value={formik.values.email}
              className='form-control'
              disabled={operation === COMPANY_FORM_OPERATIONS.EDIT}
            />
            {formik.errors.email && (
              <small className='text-danger'>{formik.errors.email}</small>
            )}
          </div>
          {/* Phone Number */}
          <div className='form-group col-md-3'>
            <label htmlFor='phone'>Phone Number</label>
            <input
              id='phone'
              name='phone'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.phone}
              className='form-control'
            />
            {formik.errors.phone && (
              <small className='text-danger'>{formik.errors.phone}</small>
            )}
          </div>
        </div>
        <div className='mx-auto'>
          <button
            type='button'
            onClick={() => navigate('/company-list')}
            className='btn btn-light m-3'
          >
            Cancel
          </button>
          <button
            type='submit'
            // disabled={!formik.isValid}
            className='btn btn-primary m-3'
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default CompanyForm;

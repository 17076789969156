import { useLocation } from 'react-router-dom';
import CompanyForm from '../components/CompanyForm';
import { useEffect, useState } from 'react';
import { useCompany } from '../contexts/CompanyContext';
const API_URI = process.env.REACT_APP_GRC_SERVICE;
const CompanyAddEdit = () => {
  const { state } = useLocation();
  const { operation, companyId } = state;
  const { env } = useCompany();
  const [companyData, setCompanyData] = useState({});
  useEffect(() => {
    companyId &&
      fetch(`${API_URI}/company/${env}/${companyId}`)
        .then((response) => response.json())
        .then((data) => {
          console.log('data:', data);
          const address = JSON.parse(data.address);
          console.log('address:', address);
          setCompanyData({
            addressLine1: address.AddressLine1,
            addressLine2: address.AddressLine2 || '',
            city: address.City,
            state: address.State,
            zip: address.ZipCode,
            country: address.Country,
            ...data,
          });
        })
        .catch((error) => {
          console.error(error);
          return (
            <div>
              Unable to get the Company detail for company with id: {companyId}
            </div>
          );
        });
  }, [operation, companyId]);
  return (
    <div className='container pt-3'>
      <CompanyForm operation={operation} companyData={companyData} />
    </div>
  );
};
export default CompanyAddEdit;

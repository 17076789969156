import 'devextreme/dist/css/dx.light.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import Home from './components/Home';
import React, { useEffect } from 'react';
import CompanyList from './pages/CompanyList.jsx';
import CompanyAddEdit from './pages/CompanyAddEdit.jsx';
import { CompanyDataProvider } from './contexts/CompanyContext.jsx';

function App() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  useEffect(() => {
    checkSession();
  }, []);

  const checkSession = async () => {
    try {
      const response = await instance.handleRedirectPromise();
      if (response && response.account) {
        // User is authenticated, you can proceed to  app
        navigate('/', { replace: true });
      } else {
        isUserLoggedIn();
      }
    } catch (ex) {
      console.log('exception occurred in checkSession');
    }
  };

  const isUserLoggedIn = async () => {
    // Check if the user is already signed in
    const account = await instance.getActiveAccount();
    if (account) {
      // User is already signed in, you can proceed to  app
      navigate('/', { replace: true });
    } else {
      // If the user is not signed in, initiate the login process

      console.log('account is null and instance : ', instance);
      await instance.initialize();
    }
  };

  return (
    <CompanyDataProvider>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/company-list' element={<CompanyList />}></Route>
        <Route path='/add-edit-company' element={<CompanyAddEdit />}></Route>
      </Routes>
    </CompanyDataProvider>
  );
}

export default App;

import { Button } from 'devextreme-react';
import {
  Column,
  DataGrid,
  FilterRow,
  Item,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { useEffect, useState } from 'react';
import { getAllCompanies } from '../services/companyService';
import { AppPages } from '../constants/genericConstants';
import { useCompany } from '../contexts/CompanyContext';
import { useNavigate } from 'react-router-dom';
import { COMPANY_FORM_OPERATIONS } from '../components/CompanyForm.constants';

function GRCCompanyGrid({ prop }) {
  const [data, setData] = useState([]);
  const [displayGrid, setDisplayGrid] = useState(false);
  // const env = prop.env;
  const { env } = useCompany();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadData() {
      const val = await getAllCompanies(env);
      if (val) {
        setDisplayGrid(true);
        setData(val);
      }
    }
    loadData();
    prop.setOnBoardPage(AppPages.Company);
  }, [prop, env]);

  function history(params) {
    // console.log('params - ', params?.data?.address);
    const address = params?.data?.address
      ? JSON.parse(params.data.address)
      : undefined;
    return (
      <p>
        {address?.AddressLine1 ?? ''}, {address?.City ?? ''},{' '}
        {address?.Country ?? ''}, {address?.State ?? ''},{' '}
        {address?.ZipCode ?? ''}
      </p>
    );
  }

  function actionRenderer(params) {
    return (
      <Button
        icon='edit'
        onClick={() =>
          navigate('/add-edit-company', {
            state: {
              operation: COMPANY_FORM_OPERATIONS.EDIT,
              companyId: params.data.companyId,
            },
          })
        }
      ></Button>
    );
  }

  return (
    <>
      {!displayGrid && <h3>Loading .. </h3>}
      {displayGrid && (
        <>
          <DataGrid
            dataSource={data}
            width={'90%'}
            showBorders={true}
            showRowLines={true}
          >
            <SearchPanel visible={true} />

            <Item>
              <Button icon='plus'></Button>
            </Item>
            <FilterRow visible={true} />
            <Column
              width={'auto'}
              caption='Edit'
              alignment='center'
              cellRender={actionRenderer}
            />
            <Column
              width={'auto'}
              dataField={'companyName'}
              caption='Name'
            ></Column>
            <Column
              width={'auto'}
              dataField={'webSite'}
              caption='Website'
            ></Column>
            <Column
              width={'auto'}
              dataField={'companySize'}
              caption='Company Size'
            ></Column>
            <Column
              width={'auto'}
              dataField={'industryType'}
              caption='Industry Type'
            ></Column>
            <Column
              width={'auto'}
              cellRender={history}
              dataField={'address'}
              caption='Address'
            ></Column>
          </DataGrid>
        </>
      )}
    </>
  );
}

export default GRCCompanyGrid;

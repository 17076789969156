import React from 'react';
import '../assets/css/index.css';
import { environments } from '../constants/genericConstants';
import { Link, useNavigate } from 'react-router-dom';
import { useCompany } from '../contexts/CompanyContext';

const Dashboard = ({ prop }) => {
  const navigate = useNavigate();
  const { setEnvironment } = useCompany();
  // const setDisplayCompany = prop.setDisplayCompany;
  // const setEnv = prop.setEnv;

  const cardClick = (env) => {
    setEnvironment(env.environment);
    navigate('/company-list');
  };
  return (
    <div id='appContainer' style={{ paddingTop: 'padding-top: x0' }}>
      <div className='card ' style={{ minHeight: '80vh' }}>
        <h5 className='card-header'>Dashboard</h5>
        <div className='card-body'>
          <div className='container' style={{ paddingTop: '25vh !important' }}>
            <div className='row'>
              {environments.map((env, i) => {
                return (
                  <div key={'dashboard-' + i} className='col-lg-3 col-sm-6'>
                    <div className={env.cardColor}>
                      <div className='inner'>
                        <h3> {env.environment} </h3>
                        <p> onboarded </p>
                      </div>
                      <div className='icon'>
                        <i className='fa fa-users' aria-hidden='true'></i>
                      </div>
                      {/* <Link to='/company'>Link</Link> */}
                      <div
                        className='card-box-footer'
                        onClick={() => cardClick(env)}
                      >
                        View <i className='fa fa-arrow-circle-right' />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useState } from 'react';
import '../assets/css/index.css';
import Dashboard from './Dashboard';
import AuthenticatedComponent from './AuthenticatedComponent';

function Home() {
  const [displayCompany, setDisplayCompany] = useState(false);
  const [env, setEnv] = useState('');
  return (
    <AuthenticatedComponent>
      <Dashboard prop={{ setDisplayCompany, setEnv }}></Dashboard>
    </AuthenticatedComponent>
  );
}

export default Home;

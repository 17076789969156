const API_URI = process.env.REACT_APP_GRC_SERVICE;

export async function getAllCompanies(env) {
  return fetch(`${API_URI}/company/getAll`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ env: env }),
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => {
      console.log('err - ', err);
    });
}

export async function createCompany(env, values) {
  return fetch(`${API_URI}/company/${env}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
}

export async function updateCompany(env, values) {
  console.log('values:', values);
  const { companyId } = values;
  return fetch(`${API_URI}/company/${env}/${companyId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
}

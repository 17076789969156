import React from 'react';
import {
    useMsal,
    UnauthenticatedTemplate,
    AuthenticatedTemplate
} from "@azure/msal-react";
import { logoutRequest } from '../config/authConfig';

const findAccountByHomeAccountId = (
    accounts, homeAccountId
) => {
    return accounts.find(
        (account) => account.homeAccountId === homeAccountId,
    );
};

function NavBar() {
    const { instance, accounts } = useMsal();
    const homeAccountId = accounts[0]?.homeAccountId;

    function signOutClickHandler(instance, accountToSignOut) {
        instance.logoutRedirect(logoutRequest(accountToSignOut))
    }

    const logout = () => {
        console.log('logout clicked');
        const accountToSignOut = findAccountByHomeAccountId(accounts, homeAccountId);
        if (accountToSignOut) {
            signOutClickHandler(instance, accountToSignOut);
        } else {
            console.error("Account not found for the given homeAccountId");
        }
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light px-5 d-flex justify-content-between">
            <a className="navbar-brand mr-auto" href="#">Clear GRC</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <UnauthenticatedTemplate>
                <button className="btn" type="button" onClick={logout}>
                    Login
                </button>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <button className="btn" type="button" onClick={logout}>
                    Logout
                </button>
            </AuthenticatedTemplate>
        </nav>
    )
}

export default NavBar;